<script lang="ts" setup>
const { t } = useT();
const { type } = useStatusSystem();
const { open } = useFunrizeModals();
const isGuest = useIsGuest();
const {
	dailyWheelData,
	isAvailableDailyWheel,
	isAvailableLuckyWheel,
	isShowDailyWheelForGuest,
	countWheels,
	refreshDailyWheelData
} = useWheels();
const { unlockWheel, lockWheel } = useWheelController();
const { isSpecialWheel } = useSpecialWheelDecor();

const reasonValue = computed(() => dailyWheelData.value?.dailyWheel?.reasonValue);
const reasonType = computed(() => dailyWheelData.value?.dailyWheel?.reasonType);
const dailyWheelDisabled = computed(
	() => !isAvailableDailyWheel.value && !isAvailableLuckyWheel.value && reasonType.value === "time"
);
const stepDailyWheel = computed(() => {
	if (reasonType.value === "phone") {
		return "verify";
	}
	if (reasonType.value === "balance") {
		return "condition";
	}
	if (reasonType.value === "time") {
		return "pending";
	}
	return "start";
});

const updateDailyWheelData = async () => {
	await refreshDailyWheelData();
	isAvailableDailyWheel.value ? unlockWheel() : lockWheel();
};

const { durationLeft, reset } = useCountdown({
	timestamp: reasonValue.value || "",
	formatToken: "HH[ h ]mm[ m ]ss[ s ]",
	onCountdownStop: () => updateDailyWheelData()
});

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: isAvailableLuckyWheel.value ? "lucky_spin" : "spin_win"
	});

	if (isGuest.value) {
		return open("LazyOModalSignup", { location: "left_menu" });
	}

	if (isAvailableLuckyWheel.value) {
		open("LazyOModalWheelLuckySpin", { location: "left_menu" });
	} else {
		open("LazyOModalWheelDaily", { location: "left_menu" });
	}

	setTimeout(() => {
		dispatchGAEvent({
			event: "open_popup",
			location: "left_menu",
			form_name: isAvailableLuckyWheel.value ? "lucky_spin" : "spin_win",
			step: isAvailableLuckyWheel.value ? "start" : stepDailyWheel.value
		});
	}, 1500);
};

watch(reasonValue, () => {
	if (reasonType.value === "time") {
		reset(reasonValue.value || "");
	}
});
</script>

<template>
	<div class="wheel-banner" @click="handleClick">
		<div class="wrapper">
			<div :class="['info', { 'opacity-50': dailyWheelDisabled }]">
				<AText :modifiers="['bold']" class="text-conakry">
					{{ isSpecialWheel ? t("Ocean Wheel") : t("Funrize Wheel") }}
				</AText>
				<AText v-if="dailyWheelDisabled" variant="topeka" class="text-cannes">
					{{ t("Next in") }} <b>{{ durationLeft }}</b>
				</AText>
			</div>
			<NuxtImg
				v-if="isSpecialWheel"
				src="/nuxt-img/sidebar/special.png"
				class="wheel-special"
				alt="funrize daily wheel"
				format="avif"
				width="166"
				height="145"
				loading="lazy"
			/>
			<NuxtImg
				:src="`/nuxt-img/sidebar/wheel-${type}.png`"
				class="wheel-img"
				alt="funrize daily wheel"
				format="avif"
				width="120"
				height="120"
				loading="lazy"
			/>
		</div>
		<ABadge v-if="isAvailableDailyWheel || isAvailableLuckyWheel || isShowDailyWheelForGuest">
			<AText variant="texas">{{ countWheels }}</AText>
		</ABadge>
	</div>
</template>

<style scoped lang="scss">
.wheel-banner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	height: 56px;
	background: var(--goma);
	border-radius: 8px;
	position: relative;
	cursor: pointer;

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		overflow: hidden;
		padding: 0 gutter(1);
		position: relative;
		height: 100%;
	}

	.wheel-img {
		transform: translateX(23px) rotate(-22.5deg);
		width: 44px;
		height: 44px;
	}

	.wheel-special {
		width: 82px;
		height: auto;
		position: absolute;
		top: -10px;
		right: -34px;
	}
}

.info {
	display: inline-flex;
	flex-direction: column;
	gap: gutter(0.25);
}

.opacity-50 {
	opacity: 0.5;
}

.badge {
	position: absolute;
	top: -6px;
	right: -6px;
	z-index: 1;
}
</style>
